import React, { useState, useEffect } from "react"
import { createStyles, WithStyles, withStyles } from "@material-ui/styles"
import classNames from "classnames"
import { graphql, StaticQuery } from "gatsby"
import { Carousel as ReactCarousel } from "react-responsive-carousel"
import BackgroundImage from "gatsby-background-image"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import "./open-positions.css"

import ThunderIcon from "../images/icons/thunder.svg"

const styles = createStyles({
  openPositions: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 50,
    paddingTop: "0 !important",
    "@media (max-width: 599.95px)": {
      padding: "40px 20px",
    },
  },
  indicator: {
    display: "inline-block",
    width: 18,
    height: 18,
    borderRadius: "50%",
    background: "#FFFFFF",
    margin: "0 7px",
    cursor: "pointer",
  },
  background: {
    marginBottom: 80,
    background: "none",
    backgroundImage: "url('../images/careers-background.svg')",
    backgroundRepeat: "repeat-x",
    width: "100vw !important",
    height: "245px !important",
    "&:after, &:before": {
      top: "calc(50% - 41px) !important",
    },
    "@media (max-width: 599.95px)": {
      marginBottom: 40,
    },
  },
  slide: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    background: "none",
    marginBottom: "60px !important",
    "@media (max-width: 599.95px)": {
      padding: "0 20px",
    },
  },
  position: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: 343,
    height: 245,
    background: "#FFFFFF",
    border: "1px solid #D2D4DD",
    fontSize: "0.94rem",
    lineHeight: "1.44rem",
    textAlign: "center",
    padding: 20,
    marginBottom: 20,
    filter: "drop-shadow(4px 4px 18px rgba(0, 0, 0, 0.1))",
    borderRadius: 10,
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 42,
    height: 42,
    borderRadius: "50%",
    background: "#FF7900",
    marginBottom: 18,
  },
  icon: {
    fill: "#FFFFFF",
    transform: "scale(1.5)",
  },
  title: {
    color: "#606060",
    marginBottom: 10,
  },
  description: {
    color: "#898989",
    marginBottom: 25,
  },
  callToAction: {
    borderRadius: 30,
    color: "#FFFFFF",
    fontSize: "0.61rem",
    lineHeight: "0.78rem",
    padding: "13.5px 50px 10px 50px",
    border: "none",
    outline: "none",
    cursor: "pointer",
    textDecoration: "none",
  },
})

export interface Position {
  title: string
  type: string
  location: string
  male: boolean
  female: boolean
  disabled: boolean
}

export interface Props {
  positions: Position[]
}

type PropsAndStyles = Props & WithStyles<typeof styles>

const Carousel = ({ classes, positions }: PropsAndStyles) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile(filter: { sourceInstanceName: { eq: "images" } }) {
          edges {
            node {
              relativePath
              childImageSharp {
                fixed(quality: 100) {
                  width
                  height
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const [isHovered, setIsHovered] = useState(-1)
      const [numberOfPositions, setNumberOfPositions] = useState(0)

      useEffect(() => {
        calculateNumberOfPositions()
        window.addEventListener("resize", calculateNumberOfPositions)

        return () => {
          window.removeEventListener("resize", calculateNumberOfPositions)
        }
      }, [])

      const calculateNumberOfPositions = () => {
        setNumberOfPositions(
          Math.max(
            Math.min(
              Math.floor(window.innerWidth / (345 + 100)),
              positions.length
            ),
            1
          )
        )
      }

      const background = data.images.edges.find(
        image => image.node.relativePath === "open-positions-background.png"
      )

      return (
        <div className={classes.openPositions}>
          <BackgroundImage
            className={classNames(
              background?.node.childImageSharp.fixed,
              classes.background
            )}
            fixed={background?.node.childImageSharp.fixed}
          >
            <ReactCarousel
              renderIndicator={(
                clickHandler: (
                  e: React.MouseEvent | React.KeyboardEvent
                ) => void,
                isSelected: boolean
              ) => (
                <div
                  className={classes.indicator}
                  style={{ background: isSelected ? "#FF7900" : "#F1F1F1" }}
                  onClick={clickHandler}
                />
              )}
              autoPlay
              centerMode
              showArrows={false}
              showStatus={false}
              showThumbs={false}
              centerSlidePercentage={100 / numberOfPositions}
              showIndicators={numberOfPositions < positions.length}
            >
              {positions.map((position, i) => {
                const {
                  title,
                  type,
                  location,
                  male,
                  female,
                  disabled,
                } = position

                const specs = []
                if (male) {
                  specs.push("m")
                }
                if (female) {
                  specs.push("f")
                }
                if (disabled) {
                  specs.push("d")
                }

                return (
                  <div className={classes.slide}>
                    <div
                      className={classes.position}
                      onMouseEnter={() => setIsHovered(i)}
                      onMouseLeave={() => setIsHovered(-1)}
                    >
                      <div
                        className={classes.iconContainer}
                        style={{
                          background: isHovered === i ? "#FF7900" : "#F1F1F1",
                        }}
                      >
                        <ThunderIcon
                          className={classes.icon}
                          style={{
                            fill: isHovered === i ? "#FFFFFF" : "#AFAFAF",
                          }}
                        />
                      </div>
                      <div className={classes.title}>
                        {title}
                        {!!specs.length && ` (${specs.join("/")})`}
                      </div>
                      <div className={classes.description}>
                        {type} {location && `| ${location}`}
                      </div>
                      <a
                        className={classes.callToAction}
                        style={{
                          background: isHovered === i ? "#FF7900" : "#AFAFAF",
                        }}
                        href={`/contact/?position=${title}`}
                      >
                        APPLY
                      </a>
                    </div>
                  </div>
                )
              })}
            </ReactCarousel>
          </BackgroundImage>
        </div>
      )
    }}
  />
)

export default withStyles(styles)(Carousel)
