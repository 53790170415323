import React from "react"
import { createStyles, WithStyles, withStyles } from "@material-ui/styles"
import { useStaticQuery, graphql } from "gatsby"
import classNames from "classnames"

import Layout from "../components/layout"
import Hero from "../components/hero"
import Heading from "../components/heading"
import OpenPositions from "../components/open-positions"
import Featured from "../components/featured"
import FeaturedDetails from "../components/featured-details"
import CallToAction from "../components/call-to-action"
import SEO from "../components/seo"
// import Carousel from "../components/carousel"

const styles = createStyles({
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: 980,
    padding: 50,
    margin: "auto",
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: "1.56x",
    textAlign: "center",
    color: "#212121",
    "@media (max-width: 599.95px)": {
      padding: 20,
      paddingTop: 40,
    },
  },
  heading: {
    marginBottom: 40,
    "@media (max-width: 599.95px)": {
      marginBottom: 20,
    },
  },
  paragraph: {
    marginBottom: 80,
    "@media (max-width: 599.95px)": {
      marginBottom: 40,
    },
  },
  coloredText: {
    color: "#FF7900",
    textDecoration: "none",
    marginLeft: 8,
    "&:hover": {
      textDecoration: "underline",
    },
  },
  greytext: {
    color: "#898989",
  },
  lastParagraph: {
    fontSize: "1.44rem",
    lineHeight: "2.78rem",
  },
  openApplication: {
    position: "relative",
    fontSize: "2rem",
    lineHeight: "2.78rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    color: " #000000",
    background: "#EFEEF0",
    padding: "100px 40px",
    fontWeight: 400,
    "@media (max-width: 599.95px)": {
      padding: "60px 20px",
    },
  },
  openApplicationText: {
    maxWidth: 700,
  },
  triangle: {
    position: "absolute",
    top: 0,
    border: "none",
    width: 0,
    height: 0,
    borderLeft: "40px solid transparent",
    borderRight: "40px solid transparent",
    borderTop: "20px solid #FFFFFF",
  },
  topTriangle: {
    top: 0,
    borderTopColor: "#FFFFFF",
  },
  bottomTriangle: {
    top: "100%",
    borderTopColor: "#EFEEF0",
    borderTopWidth: 15,
    zIndex: 2,
  },
})

type PropsAndStyles = WithStyles<typeof styles>

const CareersPage = ({ classes }: PropsAndStyles) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            positions {
              title
              type
              location
              # male
              # female
              # disabled
            }
          }
        }
      }
    `
  )

  return (
    <Layout>
      <Hero
        imagePath="hero/careers.jpg"
        title="Love"
        coloredTitle="Games?"
        description="Work on exciting projects with a talented team!"
      />

      <div className={classes.content}>
        <div className={classes.heading}>
          <Heading title="Open positions" coloredWords={1} />
        </div>

        {/* <div className={classNames(classes.coloredText, classes.paragraph)}>
          At Volt Games we want to hire attitude, skills and potential. In our
          type of work, skills need to be there for sure. But without the right
          attitude and potential to learn, our way of working and being may not
          work for everyone.
        </div>

        <div className={classes.paragraph}>
          We aim to be the best possible community for our people and we support
          them in all possible ways. We’ve built a tight-knit team of
          professionals from different disciplines, like art, design,
          development, marketing, community, user acquisition, data, QA, and in
          our flat hierarchy, everyone’s opinion is valued equally. At the end
          of the day, we’re all here to make great games - and have fun whilst
          we’re at it.
        </div>

        <div className={classNames(classes.paragraph, classes.greytext)}>
          We're always interested in talking to awesome people from different
          fields, so if you've got a burning passion for making great games,
          don't hesitate to reach out! We might just be a match made in heaven.
        </div> */}

        <div className={classNames(classes.lastParagraph, classes.coloredText)}>
          <div>We're based in beautiful Lisbon, Portugal,</div>
          <div>one of the fastest growing gaming cities in the world.</div>
        </div>
      </div>

      {/* <Carousel
        imagePaths={[
          "carousel/image-1.png",
          "carousel/image-1.png",
          "carousel/image-1.png",
          "carousel/image-1.png",
        ]}
      /> */}

      <OpenPositions positions={site.siteMetadata.positions} />

      <div className={classes.openApplication}>
        <div className={classes.openApplicationText}>
          If you didn't see anything that fits your set of skills, send an
          <a className={classes.coloredText} href="/contact/">
            open application
          </a>
          .
        </div>
        <div className={classNames(classes.triangle, classes.topTriangle)} />
        <div className={classNames(classes.triangle, classes.bottomTriangle)} />
      </div>

      <Featured imagePath="featured/careers.jpg" large>
        <FeaturedDetails
          title="Work on fully charged projects with cool people"
          description="Should you be here with us?"
          callToAction={<CallToAction text="Apply now!" href="/contact/" />}
        />
      </Featured>

      <SEO title="Careers" />
    </Layout>
  )
}

export default withStyles(styles)(CareersPage)
